import { createContext, useContext, useState } from 'react';

const UtilContext = createContext();

export const useUtils = () => {
    return useContext(UtilContext);
};

export const UtilsProvider = ({ children }) => {
    //Context to share needed functions and variables for misc use
    const formattedDates = localStorage.getItem('formattedDates') === 'true'

    const [isFormattedDatesEnabled, setIsFormattedDatesEnabled] = useState(formattedDates)

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,

        }).format(amount)
    };

    const formatDate = (dateStr,long) => {
        if (!dateStr) return '';
        

        // Parse the date to ensure it is treated as a local date
        const [year, month, day] = dateStr.split(' ')[0].split('-').map(Number);
        const date = new Date(year, month - 1, day); // Month is 0-based in JavaScript
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };

        // Handle long dates if long dates enabled in settings or was passed in to func
        if(formattedDates || long){
            options.month = 'long'
        }

        return date.toLocaleDateString(undefined, options);
    };

    

    const sum = (iter, key, initial=0) =>{
        return iter ? iter.reduce((accumulator,currentValue) =>{
            return accumulator + (key ? currentValue[key] : currentValue)
        },initial) : initial
    }

    
    

    return (
        <UtilContext.Provider value={{ formatCurrency, formatDate, sum, isFormattedDatesEnabled, setIsFormattedDatesEnabled}}>
            {children}
        </UtilContext.Provider>
    );
};
