import React, { useState } from 'react';
import '../styles/ReportsPage.css';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import ReportTable from '../components/ReportTable';

const ReportsPage = () => {
  const [selectedReport, setSelectedReport] = useState('');
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const { APP_URL, getCookie } = useAuth();
  const { formatCurrency } = useUtils();
  const { error } = useNotify();

  const reportsOptions = [
    'Accounts Receivable Aging',
    'Invoice Journal',
    'Activity Type',
    'Profit and Loss',
  ];

  const fetchReport = async () => {
    setLoading(true);
    const requestBody = { report_name: selectedReport };

    if (!selectedReport) {
      error('Please select a report');
      setLoading(false);
      return;
    }

    if (selectedReport !== 'Accounts Receivable Aging' && (!startDate || !endDate)) {
      error('Start and end date must be selected');
      setLoading(false);
      return;
    }

    if (startDate && endDate) {
      requestBody.start_date = startDate;
      requestBody.end_date = endDate;
    }

    fetch(`${APP_URL}/reports`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }).then(resp =>{
      if (resp.ok) {
        resp.json().then(setReportData)
      } else {
        error('Failed to fetch report data');
      }
    }).catch(e => error(e))
    .finally(setLoading(false))
  };

  return (
    <div className="container mx-auto p-4 mt-8 bg-white rounded shadow">
      <h2 className="text-2xl font-bold mb-4">Reports</h2>
      <div className="mb-3">
        <label htmlFor="report-dropdown" className="form-label">
          Select Report:
        </label>
        <select
          id="report-dropdown"
          className="form-select w-1/3"
          value={selectedReport}
          onChange={(e) => setSelectedReport(e.target.value)}
        >
          <option value="">Select a Report</option>
          {reportsOptions.map((report) => (
            <option key={report} value={report}>
              {report}
            </option>
          ))}
        </select>
      </div>

      {selectedReport !== 'Accounts Receivable Aging' && selectedReport && (
        <div className="mb-3 flex items-center">
          <div className="mr-4">
            <label htmlFor="start-date" className="form-label">
              Start Date:
            </label>
            <input
              type="date"
              id="start-date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="end-date" className="form-label">
              End Date:
            </label>
            <input
              type="date"
              id="end-date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="mb-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
          onClick={fetchReport}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Fetch Data'}
        </button>
      </div>

      {loading && <p>Loading...</p>}

      {reportData && !loading && (
        <div>
          <h3 className="text-xl font-bold mb-2">{selectedReport}</h3>
          <ReportTable data={reportData} />
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
