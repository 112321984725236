import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/themes/prism.css';

const AdminLogs = () => {
  const [logFiles, setLogFiles] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [logContent, setLogContent] = useState('');
  const [loading, setLoading] = useState(false);

  const { error, success } = useNotify();
  const { APP_URL } = useAuth();

  useEffect(() => {
    setLoading(true);
    fetch(`${APP_URL}/AWS/logs/all`,{
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(resp => {
        if(resp.ok){
            resp.json().then(data => setLogFiles(data.slice(1)))
            setLoading(false)
        }else{
            error(resp)
        }
    }).catch(e => error(e))
  }, [APP_URL, success, error]);

  const handleLogSelect = (filename) => {
    const fileName = filename.slice(8)
    setSelectedLog(filename);
    setLogContent('Loading...')
    setLoading(true)
    fetch(`${APP_URL}/AWS/logs/${fileName}`,{
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(resp => {
        if(resp.ok){
            resp.json().then(data => setLogContent(data))
            setLoading(false)
        }else{
            error(resp)
        }
    }).catch(e => error(e))
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-6">Admin Logs</h2>
      <div className="flex flex-row gap-6">
        <div className="w-1/3 bg-gray-50 p-4 border border-gray-200 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Log Files</h3>
          {loading ? (
            <p className="text-gray-500">Loading logs...</p>
          ) : (
            <ul className="space-y-2">
              {logFiles.map((filename, index) => (
                <li
                  key={index}
                  className={`p-2 cursor-pointer rounded-md ${
                    selectedLog === filename ? 'bg-blue-100' : 'hover:bg-gray-100'
                  }`}
                  onClick={() => handleLogSelect(filename)}
                >
                  {filename}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="w-2/3 bg-gray-50 p-4 border border-gray-200 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Log Viewer</h3>
          {selectedLog ? (
            <Editor
              value={logContent}
              onValueChange={(code) => setLogContent(code)}
              highlight={(code) => highlight(code, languages.js, 'javascript')}
              padding={10}
              className="border border-gray-300 rounded-md text-sm overflow-auto"
              style={{
                fontFamily: 'monospace',
                minHeight: '300px',
                backgroundColor: '#f8f8f8',
              }}
            />
          ) : (
            <p className="text-gray-500">Select a log file to view its content</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogs;
