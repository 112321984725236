import { useUtils } from "../context/UtilContext";

const ReportTable = ({ data }) => {

  const { formatCurrency } = useUtils()

  if (!data || data.length === 0) return <p>No data available</p>;

  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            {data.headers.map((header, index) => (
              <th key={index} className="p-2 border border-gray-300">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.data.map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-100">
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={`p-2 border border-gray-300 ${
                    typeof cell === 'number' ? 'text-right' : ''
                  }`}
                >
                  {typeof cell == 'number' ? formatCurrency(cell) : cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;