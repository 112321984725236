import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';

const ClientActivities = () => {
    const { id } = useParams();
    const { getCookie, APP_URL } = useAuth();
    const { success, error } = useNotify();
    const [client, setClient] = useState({})
    const [selectedTypes, setSelectedTypes] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // The predefined 15 activity types
    const activityTypes = [
        { id: 1, chargeType: 'Chargeable', name: 'Advisory' },
        { id: 2, chargeType: 'Chargeable', name: 'Audit' },
        { id: 3, chargeType: 'Chargeable', name: 'Agreed Upon Procedure' },
        { id: 4, chargeType: 'Chargeable', name: 'Bookkeeping' },
        { id: 5, chargeType: 'Chargeable', name: 'Compilation' },
        { id: 6, chargeType: 'Chargeable', name: 'Review' },
        { id: 7, chargeType: 'Chargeable', name: 'Tax Return Preparation' },
        { id: 8, chargeType: 'Chargeable', name: 'Exempt' },
        { id: 9, chargeType: 'Non-Chargeable', name: 'General' },
        { id: 10, chargeType: 'Non-Chargeable', name: 'Vacation' },
        { id: 11, chargeType: 'Non-Chargeable', name: 'Sick' },
        { id: 12, chargeType: 'Non-Chargeable', name: 'Comp Time' },
        { id: 13, chargeType: 'Non-Chargeable', name: 'Continuing Professional Education' },
        { id: 14, chargeType: 'Non-Chargeable', name: 'Authorized Time Off' },
        { id: 15, chargeType: 'Non-Chargeable', name: 'Holiday' }
    ];

    useEffect(() => {
        fetch(`${APP_URL}/clients/${id}/activity-types`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        const selected = new Set(data.map(type => type.id));
                        setSelectedTypes(selected);
                        fetch(`${APP_URL}/clients/${id}`, {
                            credentials: 'include'
                        }).then(resp => {
                            if (resp.ok) {
                                resp.json().then(data => {
                                    setLoading(false)
                                    setClient(data)
                                });
                            } else {
                                error(resp);
                                navigate('/error');
                            }
                        })
                    });
                } else {
                    error(resp);
                    navigate('/error');
                }
            })
            .catch(e => {
                error(e);
                setLoading(false);
            });


    }, [id, APP_URL, error, navigate]);

    const handleCheckboxChange = (typeId) => {
        setSelectedTypes(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(typeId)) {
                newSelected.delete(typeId);
            } else {
                newSelected.add(typeId);
            }
            return newSelected;
        });
    };

    const handleSelectAll = () =>{
        if(selectedTypes.size === activityTypes.length){
            setSelectedTypes(new Set())
            return
        }

        const ids = []
        activityTypes.forEach(activity => ids.push(activity.id))
        setSelectedTypes(new Set(ids))
    }

    const handleSubmit = () => {
        const activities = activityTypes.map(type => ({
            activity_id: type.id,
            enabled: selectedTypes.has(type.id),
        }));

        fetch(`${APP_URL}/clients/${id}/activity-types`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            body: JSON.stringify({ activities }),
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    success('Activity types updated successfully!');
                    navigate(`/clients/${id}`)
                } else {
                    error(resp);
                }
            })
            .catch(e => error(e));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <div className="table table-responsive">
                <h2 className="text-2xl font-bold">Activity Types for {client.legal_name}</h2>
                <button className='button right' onClick={handleSelectAll}>Select All</button>
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="px-4 py-2">Charge Type</th>
                            <th className="px-4 py-2">Description</th>
                            <th className="px-4 py-2 text-center ">
                                <button onClick={handleSelectAll}>Select All</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityTypes.filter(type => parseInt(id) !== 0 ? type.chargeType === 'Chargeable' : true).map(type => (
                            <tr key={type.id} onClick={(e) => {
                                console.log(e.target)
                                handleCheckboxChange(type.id)}
                                }>
                                <td className="border px-4 py-2">{type.chargeType}</td>
                                <td className="border px-4 py-2">{type.name}</td>
                                <td className="border px-4 py-2 text-center">
                                    <input
                                        type="checkbox"
                                        checked={selectedTypes.has(type.id)}
                                        readOnly
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default ClientActivities;
