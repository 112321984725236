import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../assets/clockwise.png';
import '../styles/Dashboard.css';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const popup = searchParams.get('popup') ? true : false;
  const [showLoginForm, setShowLoginForm] = useState(popup);
  const [showContactForm, setShowContactForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);

  const { error, success, spinner } = useNotify();
  const navigate = useNavigate();
  const { login, APP_URL, getCookie, handle2FASubmit, user } = useAuth();

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false);
  };

  const handleShowResetPasswordForm = () => {
    setShowResetPasswordForm(true);
  };

  const handleCloseResetPasswordForm = () => {
    setShowResetPasswordForm(false);
  };


  const loginFormik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(5, 'Username must be at least 5 characters.')
        .max(15, 'Username must be at most 15 characters')
        .required('Username is required'),
      password: Yup.string()
        .min(5, 'Password must be at least 5 characters.')
        .max(15, 'Password must be at most 15 characters')
        .required('Password is required'),
    }),
    onSubmit: (values) => {
    //   spinner('Logging in',{
    //     conditionChecker: () => show2FAModal
    // })
      login(values.username, values.password, (message) =>{
        if(message.success){
          setShow2FAModal(true)
        }else{
          navigate('/')
        }
      })
    },
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      fetch(`${APP_URL}/reset_password/send`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({ email: values.email }),
      })
        .then((resp) => {
          if (resp.ok) {
            success('Password reset email sent!');
            setLoading(false);
          } else {
            error(resp);
            setLoading(false);
          }
        })
        .catch((e) => error(e));
    },
  });


  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Navigation */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <button
              className="text-gray-700 text-lg font-semibold"
              onClick={handleContactClick}
            >
              Get In Contact
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-800 mb-4">Clockwise</h1>
            <p className="text-lg text-gray-600 mb-8">
              <i>When time is your asset</i>
            </p>
            <img src={logo} alt="" className="w-64 h-auto mx-auto mb-8" />
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => setShowLoginForm(true)}
            >
              Log In
            </button>
          </div>
        </div>
      </section>

      {/* Login Form */}
      {showLoginForm && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="card my-5 mx-auto" style={{ background: 'linear-gradient(135deg, #3498db, #6cb1ed)', borderRadius: '1rem' }}>
            <button
              className="absolute top-3 right-3 text-white text-lg focus:outline-none"
              onClick={() => setShowLoginForm(false)}
            >
              <span>&times;</span>
            </button>
            <div className="card-body p-5 d-flex flex-column align-items-center">
              <h2 className="fw-bold mb-2 text-uppercase text-white">Login</h2>
              <p className="text-white mb-5">Please enter your login and password!</p>
              <form onSubmit={loginFormik.handleSubmit}>
                <div className="mb-4 w-100">
                  <label className="text-white" htmlFor="username">
                    Username:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={loginFormik.values.username}
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                  />
                  {loginFormik.touched.username && loginFormik.errors.username && (
                    <div className="text-red-500">{loginFormik.errors.username}</div>
                  )}
                </div>
                <div className="mb-4 w-100">
                  <label className="text-white" htmlFor="password">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={loginFormik.values.password}
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                  />
                  {loginFormik.touched.password && loginFormik.errors.password && (
                    <div className="text-red-500">{loginFormik.errors.password}</div>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-outline-light mx-2 px-5">
                    Login
                  </button>
                </div>
              </form>
              <button
                className="text-white mt-4 underline"
                onClick={handleShowResetPasswordForm}
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Form */}
      {showResetPasswordForm && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="card my-5 mx-auto p-5 bg-white">
            <button
              className="absolute top-3 right-3 text-gray-700 text-lg focus:outline-none"
              onClick={handleCloseResetPasswordForm}
            >
              <span>&times;</span>
            </button>
            <h2 className="text-2xl font-semibold mb-4">Reset Password</h2>
            <p className="mb-4">Enter your email address to reset your password.</p>
            <form onSubmit={resetPasswordFormik.handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-semibold">
                  Email Address:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={resetPasswordFormik.values.email}
                  onChange={resetPasswordFormik.handleChange}
                  onBlur={resetPasswordFormik.handleBlur}
                />
                {resetPasswordFormik.touched.email && resetPasswordFormik.errors.email && (
                  <div className="text-red-500">{resetPasswordFormik.errors.email}</div>
                )}
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'Loading...' : 'Send Reset Link'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* 2FA Modal */}
      {show2FAModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="card my-5 mx-auto p-5 bg-white">
            <button
              className="absolute top-3 right-3 text-gray-700 text-lg focus:outline-none"
              onClick={() => setShow2FAModal(false)}
            >
              <span>&times;</span>
            </button>
            <h2 className="text-2xl font-semibold mb-4">Two-Factor Authentication</h2>
            <p className="mb-4">Enter the authentication code sent to your device.</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const code = e.target.elements.code.value;
                handle2FASubmit(code).then(data =>{
                  if(data && !data.error){
                    success('Logged in!')
                    navigate('/')
                  }else if(data.error){
                    error(data)
                  }
                })
              }}
            >
              <div className="mb-4">
                <label htmlFor="code" className="block text-gray-700 font-semibold">
                  Authentication Code:
                </label>
                <input type="text" id="code" name="code" className="form-control" />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Verify Code
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
