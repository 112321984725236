import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';

const WIPQueue = () => {
  const [showModal, setShowModal] = useState(false);
  const [client, setClient] = useState({});
  const [invoiceTexts, setInvoiceTexts] = useState([]);
  const [selectedInvoiceTextId, setSelectedInvoiceTextId] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [isClosing, setIsClosing] = useState(false);

  const { APP_URL, getCookie } = useAuth();
  const { success, error } = useNotify();
  const { formatCurrency } = useUtils();
  const { clientId } = useParams();
  const navigate = useNavigate();

  const getLocalDate = () => {
    const localDate = new Date();
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(getLocalDate());

  useEffect(() => {
    if (clientId) {
      fetch(`${APP_URL}/clients/${clientId}`, {
        headers: { Accept: 'application/json' },
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setClient);
          } else {
            error(resp);
          }
        })
        .catch((e) => error(e));
    }
  }, [clientId, APP_URL, error]);

  useEffect(() => {
    fetch(`${APP_URL}/invoice_texts`, { credentials: 'include' })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setInvoiceTexts);
        } else {
          error(resp);
        }
      })
      .catch((e) => error(e));
  }, [APP_URL, error]);

  const handleOpenModal = (closing) => {
    setIsClosing(closing);
    setShowModal(true);
  };

  const handleSubmit = () => {
    if (!selectedInvoiceTextId || (!totalAmount && !isClosing) || !startDate) {
      error('Please fill in all fields');
      return;
    }

    const payload = {
      invoice_text_id: selectedInvoiceTextId,
      close: isClosing,
      total: totalAmount,
      start_date: startDate,
      client_id: clientId
    };

    fetch(`${APP_URL}/invoices`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((resp) => {
        if (resp.ok) {
          success('Invoice generated');
          setShowModal(false);
          resp.json().then((data) => navigate(`/invoices/${data.success}`));
        } else {
          error(resp);
        }
      })
      .catch((e) => error(e));
  };

  const invoiceTextOptions = invoiceTexts.map((text) => ({
    value: text.id,
    label: text.name,
  }));

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-6">{client.legal_name}</h2>

      <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
        <h3 className="text-xl font-semibold mb-2">Client Information</h3>
        <p><strong>ID:</strong> {client.client_id}</p>
        <p><strong>Email:</strong> {client.email || 'Not provided'}</p>
        <p><strong>Status:</strong> {client.status || 'Active'}</p>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
        <h3 className="text-xl font-semibold mb-2">Key Metrics</h3>
        <p><strong>Total Outstanding Invoices:</strong> {formatCurrency(client.outstanding_invoices || 0)}</p>
        <p><strong>Total Payments Received:</strong> {formatCurrency(client.payments_received || 0)}</p>
        <p><strong>Hours Logged:</strong> {client.hours_logged || 0} hrs</p>
      </div>

      <div className="flex justify-center space-x-4 mb-6">
        <button
          className="dashboard-button"
          onClick={() => handleOpenModal(false)}
        >
          Progress Bill
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleOpenModal(true)}
        >
          Close Out Client
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <div className="mb-4">
              <h5 className="text-xl font-semibold mb-2">
                {isClosing ? 'Close out Client' : 'Create Invoice'}
              </h5>
              <button
                type="button"
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={() => setShowModal(false)}
              >
                &times;
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Invoice Text</label>
              <Select
                options={invoiceTextOptions}
                value={invoiceTextOptions.find((option) => option.value === selectedInvoiceTextId)}
                onChange={(selectedOption) => setSelectedInvoiceTextId(selectedOption.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Start Date</label>
              <input
                type="date"
                className="w-full p-3 border border-gray-300 rounded-lg"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            {!isClosing &&
              <div className="mb-4">
              <label className="block text-gray-700 mb-2">Total Amount</label>
              <input
                type="number"
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Enter total amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
              />
            </div>
            }
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-lg"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg"
                onClick={handleSubmit}
              >
                {isClosing ? 'Close out client' : 'Create Invoice'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WIPQueue;
