import React from 'react';
import { Link } from 'react-router-dom';

const AdminPage = () => {
  return (
    <div className="container mt-4">
      <h2 className="text-2xl font-bold">Admin Page</h2>

      {/* User Operations */}
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">User Operations</h5>
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/users/create" className="text-blue-500">
                Create User
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/users/manage" className="text-blue-500">
                Manage Users
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Client Operations */}
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Client Operations</h5>
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/clients/create" className="text-blue-500">
                Create Client
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/clients/manage" className="text-blue-500">
                Manage Clients
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/activity-types" className="text-blue-500">
                Activity Types
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Monetary Operations */}
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Monetary Operations</h5>
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/reports" className="text-blue-500">
                Reports
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/billing" className="text-blue-500">
                Billing
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Report Operations */}
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Invoice Operations</h5>
          <ul className="list-group">
          {/* <li className="list-group-item">
              <Link to="/wip" className="text-blue-500">
                WIP Queue
              </Link>
            </li> */}
            <li className="list-group-item">
              <Link to="/invoice_texts/create" className="text-blue-500">
                Create Invoice Text
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/invoice_texts/manage" className="text-blue-500">
                Manage Invoice Text
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Admin Operations</h5>
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/admin/logs" className="text-blue-500">
                View Backups
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
