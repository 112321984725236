import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col, Button, Table, ListGroup } from 'react-bootstrap';
import '../styles/Dashboard.css'; 
import { useUtils } from '../context/UtilContext';

const Dashboard = () => {
    const { user, APP_URL } = useAuth();
    const { formatDate } = useUtils();
    const { error } = useNotify();
    const [activities, setActivities] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [partners, setPartners] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        fetch(`${APP_URL}/activities`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(setActivities);
                } else {
                    console.error('Error fetching activities');
                }
            })
            .catch((e) => error(e));

        fetch(`${APP_URL}/invoices`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(setInvoices);
                } else {
                    console.error('Error fetching invoices');
                }
            })
            .catch((e) => error(e));

        fetch(`${APP_URL}/users/${user?.id}`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(data => setPartners(data.partnered_clients));
                } else {
                    error(resp);
                }
            })
            .catch((e) => error(e));

        // Update the current date and time every second
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date().toLocaleString());
        }, 1000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []); //eslint-disable-line

    return user ? (
        <div className="dashboard">
            <div className="main-content">
                <header className="dashboard-header">
                    <h1>Welcome, {user.username}</h1>
                    <p>It is currently, {currentDateTime}</p>
                </header>
                <div className="dashboard-content">
                    <div className="dashboard-grid">
                    {user.role === 'Partner' &&
                    <>
                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Billing</div>
                            <div className="dashboard-card-body text-center">
                                <p>View Client Totals</p>
                                <a href="/billing" className="dashboard-button">Go to Billing</a>
                            </div>
                        </div>

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Payments</div>
                            <div className="dashboard-card-body text-center">
                                <p>Record Client Payments</p>
                                <a href="/clients/manage" className="dashboard-button">Record Payments</a>
                            </div>
                        </div>

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Client Operations</div>
                            <div className="dashboard-card-body text-center">
                            <p>Manage Clients</p>
                                <a href="/clients/manage" className="dashboard-button">Manage Clients</a>
                            </div>
                        </div>

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Reports</div>
                            <div className="dashboard-card-body text-center">
                            <p>View client reports</p>
                                <a href="/reports" className="dashboard-button">Reports</a>
                            </div>
                        </div>

                        </>
                    }

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Time and Expense</div>
                            <div className="dashboard-card-body text-center">
                                <p>Record Your Time</p>
                                <a href="/profile" className="dashboard-button">Record Time</a>
                            </div>
                        </div>

                        

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Partners</div>
                            <div className="dashboard-card-body">
                            {partners?.length > 0 ?
                                <ul className="dashboard-list-group">
                                    {partners.slice(0,3).map(partner =>

                                        <li key={partner.id} className="dashboard-list-group-item">{partner.legal_name} - Client ID {partner.client_id}</li>

                                    )}
                                </ul>
                                :<div className='no-partners'>No partners to display.</div>
                            }
                            </div>
                        </div>

                        {user.role === 'Partner' && 
                        
                            <div className="dashboard-card">
                            <div className="dashboard-card-header">Latest Invoices</div>
                            <div className="dashboard-card-body">
                            {invoices?.length > 0? 
                                <ul className="dashboard-list-group">
                                            {invoices.slice(0,3).map(invoice =>
                                                <li key={invoice.id} className="dashboard-list-group-item">{invoice.id}</li>

                                            )}
                                            </ul>
                                : <div className='no-invoices'>No invoices to display.</div>
                            }
                            </div>
                        </div>

                        }

                        <div className="dashboard-card">
                            <div className="dashboard-card-header">Recent Activity</div>
                            <div className="dashboard-card-body">
                            {activities?.length > 0 ?
                                <ul className="dashboard-list-group">
                                    {activities.slice(0, 3).map(activity =>

                                        <li key={activity.id} className="dashboard-list-group-item">{activity.action}</li>

                                    )}
                                </ul>
                                : <div className='no-activities'>No activities to display.</div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Dashboard;
