
import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import Notiflix from "notiflix";
import { PulseLoader } from 'react-spinners';
// import toast from 'react-hot-toast'


const NotifyContext = createContext();

export const useNotify = () => {
    return useContext(NotifyContext);
};

export const NotifyProvider = ({ children }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [loading, setLoading] = useState(true)

    const notiflixOptions = {
        success: Notiflix.Notify.success,
        failure: Notiflix.Notify.failure,
        info: Notiflix.Notify.info
    }

    const notistackOptions = {
        success: (message, ...args) => enqueueSnackbar(message, { variant: 'success', ...args }),
        failure: (message, ...args) => enqueueSnackbar(message, { variant: 'error', ...args }),
        info: (message, ...args) => enqueueSnackbar(message, { variant: 'info', ...args }),
    }

    const selectedLib = notistackOptions

    if (selectedLib === notiflixOptions) {

        // Init Notify Module
        Notiflix.Notify.init({});

        // Init Report Module
        Notiflix.Report.init({});

        // Init Confirm Module
        Notiflix.Confirm.init({});

        // Init Loading Module
        Notiflix.Loading.init({});
    }

    const extractMessage = (response) => {

        // console.log('Extracting message')

        const findMessage = (data, _resolve) => {
            if (data.message) _resolve(data.message);
            else if (data.error) _resolve(Array.isArray(data.error) ? data.error[0] : data.error);
            else if (data.msg) data.msg === 'Token has expired' ? _resolve(data.msg + ' please refresh') : _resolve(data.msg)
            else if (data.success) _resolve(data.success);
            else _resolve('An unexpected error has occurred');
        };

        return new Promise((resolve) => {
            if (response && response.json) {
                response.json().then((data) => {
                    findMessage(data, resolve);
                });
            } else {
                if (response.statusText) resolve(response.statusText);
                else if (response.status) resolve(`Error ${response.status}`);
                else findMessage(response, resolve);
            }
        });
    };


    const handleMessage = (response, messageType, prefix) => {
        extractMessage(response).then((successMessage) => {
            messageType(successMessage, prefix);
        });
    };

    const error = (message, prefix) => {

        console.error('An error has occured:', message)

        console.log(typeof message)
        console.log(Object.keys(message))

        if (message && typeof message === 'object') {
            // If message is an object, treat it as a response object
            handleMessage(message, error, prefix);
        } else {
            // Treat message as a string
            selectedLib.failure(`${prefix || 'Error'}: ${message || 'An unexpected error has occurred'}`);
        }
    };
    //Check for data.msg or data.error or data.message or data.success

    const success = (message, prefix) => {

        if (message && typeof message === 'object') {
            // If message is an object, treat it as a response object
            handleMessage(message, success, prefix);
        } else {
            // Treat message as a string
            selectedLib.success(`${prefix || ''} ${message || 'Success!'}`);
        }
    };

    const spinner = (message = 'Loading, please wait...', options = {}) => {
        const { 
          conditionChecker, 
          timeout = 5000, 
          postCompletionDelay = 1000,
          successMessage = 'Operation completed successfully!' 
        } = options;
      
        // Show initial loading spinner
        const key = enqueueSnackbar(message, {
          persist: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          content: (key) => (
            <div
              id={`spinner-${key}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 15px',
                background: '#333',
                color: '#fff',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              }}
            >
              <PulseLoader color="#ffffff" size={10} style={{ marginRight: '10px' }} />
              <span>{message}</span>
              <button
                onClick={() => closeSnackbar(key)}
                style={{
                  marginLeft: '15px',
                  background: 'transparent',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                ✖
              </button>
            </div>
          ),
        });
      
        // Monitor condition or fallback to timeout
        if (conditionChecker) {
          const interval = setInterval(() => {
            console.log(1200)
            console.log(conditionChecker())
            if (conditionChecker()) {
              clearInterval(interval);
              // Update the spinner to success state
              enqueueSnackbar(successMessage, {
                key: (key + 1),
                persist: true,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                content: (key) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px 15px',
                      background: '#28a745', // Green background for success
                      color: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                      }}
                    >
                      ✔️
                    </span>
                    <span>{successMessage}</span>
                    <button
                      onClick={() => closeSnackbar(key)}
                      style={{
                        marginLeft: '15px',
                        background: 'transparent',
                        color: '#fff',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      ✖
                    </button>
                  </div>
                ),
              });
      
              // Close the notification after the post-completion delay
              setTimeout(() => {
                closeSnackbar((key));
                closeSnackbar((key+1));
              }, postCompletionDelay);
            }
          }, 100); // Polling interval for condition
        } else {
          // Fallback timeout to auto-close
          setTimeout(() => {
            closeSnackbar(key);
          }, timeout);
        }
      
        return key; // Return the key for manual control
      };
      


    const info = (message, prefix) => {

        if (message && typeof message === 'object') {
            // If message is an object, treat it as a response object
            handleMessage(message, info, prefix);
        } else {
            // Treat message as a string
            selectedLib.info(`${prefix || ''}: ${message || 'Success!'}`);
        }
    };

    const choice = (message, _success, _cancel, options = { button1: 'Yes', button2: 'No', messageStyle: { 'fontWeight': 'bold' } }) => {
        const choicePrompt = (
            <div className="alert alert-info">
                <p className="mb-0" style={options.messageStyle}>
                    {message}
                </p>
                <div className="mt-2">
                    <button className="btn btn-danger mr-2" type='button' onClick={() => {
                        closeSnackbar()
                        if (_success) {
                            _success()
                        }
                    }}>
                        {options['button1']}
                    </button>
                    <button className="btn btn-secondary" type='button' onClick={() => {
                        closeSnackbar()
                        if (_cancel) {
                            _cancel()
                        }
                    }}>
                        {options['button2']}
                    </button>
                </div>
            </div>
        );

        enqueueSnackbar(choicePrompt, {
            variant: 'info',
            persist: true,
        });
    }

    return (
        <NotifyContext.Provider value={{ error, success, spinner, choice, info }}>
            {children}
        </NotifyContext.Provider>
    );
};
